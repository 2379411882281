import React from 'react';
import { graphql } from 'gatsby';
import { Typography } from '@mui/material';
import { StaticImage } from 'gatsby-plugin-image';
import ContentContainer from '../../styles/content-container';
import Layout from '../../components/Layout';
import { SEO } from '../../components/Seo';

import '../../../static/content.css';

const width = ['97%', '95%', '55%', '55%', '55%'];

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data; // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark;

  const { tags } = frontmatter;
  const postdate = `Post Date  ${frontmatter.date}`;

  return (
    <Layout>
      <ContentContainer sx={{ textAlign: 'center', marginBottom: '64px', width }}>
        <Typography variant="h1">{frontmatter.title}</Typography>
      </ContentContainer>
      <ContentContainer
        sx={{
          display: 'flex',
          flexDirection: 'row',
          marginBottom: '32px',
          alignItems: 'center',
          width,
        }}
      >
        {
          frontmatter.profile_picture ?
          (<img
            src={frontmatter.profile_picture}
            alt="No author profile"
            style={{ marginRight: '16px', maxHeight: '80px', borderRadius: '50%' }}
          />) :
          (<StaticImage
            src='../../img/anon.svg'
            alt="No author profile"
            style={{ marginRight: '16px', maxHeight: '80px', borderRadius: '50%' }}
            placeholder="blurred"
            loading="eager"
          />)
        }
        <div>
          <Typography variant="h5">{frontmatter.author}</Typography>
          <Typography variant="body2" sx={{ color: '#bfbfbf' }}>
            {frontmatter.author_title ? frontmatter.author_title : 'Author Title not setup'}
          </Typography>
        </div>
      </ContentContainer>
      <ContentContainer sx={{ marginBottom: '16px', width }}>
        <Typography variant="h5" sx={{ fontWeight: 300 }}>
          {postdate}
        </Typography>
      </ContentContainer>
      <ContentContainer
        sx={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          marginBottom: '24px',
          width,
          gap: '8px',
        }}
      >
        {tags !== null &&
          tags.map(
            (tag) =>
              tag !== 'Featured' &&
              tag !== 'Vault' &&
              tag !== 'One' &&
              tag !== 'Two' &&
              tag !== 'News' && (
                <Typography
                  variant="tag"
                  sx={{
                    border: '1px solid #FD6F3E',
                    borderRadius: '6px',
                    padding: '3px 8px',
                    display: 'inline-block',
                    textAlign: 'center',
                    marginBottom: '8px',
                  }}
                  key={tag}
                >
                  {tag}
                </Typography>
              ),
          )}
      </ContentContainer>
      <ContentContainer sx={{ marginBottom: '8%', width }} className="author-content">
        <div dangerouslySetInnerHTML={{ __html: html }} />
      </ContentContainer>
    </Layout>
  );
}

export const Head = () => (
  <SEO />
)

export const pageQuery = graphql`
  query ($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
        author
        author_title
        profile_picture
        tags
      }
    }
  }
`;
